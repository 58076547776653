
import Pagination from '@/common/pagination.vue'
import { serviceTypes, serviceFeatures } from '@/api/entService'
import { PageData } from '@/types/index'
import bottomBanner from '@/common/bottomBanner.vue'
import router from '@/router'
import waist from '../components/waist.vue'
import { defineComponent, ref } from 'vue'
export default defineComponent({
  name: 'freeTrial',
  components: {
    Pagination,
    waist,
    bottomBanner
  },
  setup() {
    // 变量
    const serviceTypeList = ref([])
    const serviceProdList = ref([])
    const serPage = ref<PageData>({ currentPageNum: 1, recordsPerPage: 12 })
    const serviceTotal = ref(0)
    const typeId = ref('')
    // 方法
    // 请求列表
    const searchFn = () => {
      const params = Object.assign(
        { parentTid: typeId.value + '' },
        { paginate: serPage.value }
      )
      serviceFeatures(params).then((res: any) => {
        serviceTotal.value = res.data.total
        res.data.data.forEach((item: any) => {
          if (item.money !== '面议') {
            item.money = '￥' + item.money + '起'
          }
        })
        serviceProdList.value = res.data.data
      })
    }
    // 菜单点击选择
    const menuSelect = (key: any) => {
      typeId.value = key
      serPage.value.currentPageNum = 1
      searchFn()
    }
    // 点击立即咨询
    const consultFn = (id: any) => {
      router.push({
        path: '/entService/serviceConsult',
        query: { id }
      })
    }
    // 获取服务分类
    const getServiceType = () => {
      serviceTypes().then((res: any) => {
        res.data[0].id = ''
        serviceTypeList.value = res.data
        menuSelect(res.data[0].id + '')
      })
    }
    getServiceType()
    return {
      serviceTypeList,
      serviceProdList,
      serPage,
      serviceTotal,
      menuSelect,
      searchFn,
      consultFn
    }
  }
})
