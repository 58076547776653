<template>
  <el-pagination
    class="pagination"
    background
    small
    layout="prev, pager, next"
    :total="total"
    :pageSize="pageSize"
    :currentPage="currentPage"
    @size-change="sizeChange"
    @current-change="currentChange"
  >
  </el-pagination>
</template>

<script lang="ts">
import { defineComponent, nextTick } from 'vue'

export default defineComponent({
  props: {
    pageSize: {
      type: Number,
      default: 10
    },
    total: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },
  setup(props, ct) {
    const sizeChange = (v: number) => {
      ct.emit('update:pageSize', v)
      ct.emit('update:currentPage', 1)
      nextTick(() => {
        ct.emit(
          'search',
          Object.assign({}, props, { pageSize: v, currentPage: 1 })
        )
      })
    }
    const currentChange = (v: number) => {
      ct.emit('update:currentPage', v)
      nextTick(() => {
        ct.emit('search', Object.assign({}, props, { currentPage: v }))
      })
    }
    return {
      sizeChange,
      currentChange
    }
  }
})
</script>

<style lang="scss" scoped>
@mixin paginationItem {
  background-color: #fff !important;
  border: 1px solid #eaeaea !important;
  color: #606266 !important;
  font-size: 14px !important;
  font-weight: normal;
}
:deep(.el-pagination.is-background) .btn-next {
  @include paginationItem();
  color: red !important;
}
:deep(.el-pager li) {
  @include paginationItem() &.active {
    color: #fff !important;
    background-color: #3c89ff !important;
    border-color: transparent !important;
  }
}
</style>
