
import { defineComponent, nextTick } from 'vue'

export default defineComponent({
  props: {
    pageSize: {
      type: Number,
      default: 10
    },
    total: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },
  setup(props, ct) {
    const sizeChange = (v: number) => {
      ct.emit('update:pageSize', v)
      ct.emit('update:currentPage', 1)
      nextTick(() => {
        ct.emit(
          'search',
          Object.assign({}, props, { pageSize: v, currentPage: 1 })
        )
      })
    }
    const currentChange = (v: number) => {
      ct.emit('update:currentPage', v)
      nextTick(() => {
        ct.emit('search', Object.assign({}, props, { currentPage: v }))
      })
    }
    return {
      sizeChange,
      currentChange
    }
  }
})
