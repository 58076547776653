
import { useStore } from 'vuex'
import {
  defineComponent,
  computed
} from 'vue'
export default defineComponent({
  setup() {
    const stores = useStore()
    const isShow = computed({
      get() {
        return stores.state.sideBarOnlineStatus
      },
      set(v) {
        stores.commit('changeSideBarOnlineStatus', v)
      }
    })
    const subInfo = () => {
      isShow.value = true
    }
    return {
      subInfo
    }
  }
})
