<template>
  <div class="freeTrial">
    <div class="banner">
      <div class="main_width">
        <p>满足企业所需多种服务，保驾护航</p>
        <h2>慧点企业管家</h2>
      </div>
    </div>
    <div class="prod_container main_width">
      <div class="title">热门产品，更多用户的选择</div>
      <div class="prod_content">
        <div class="menu" v-if="serviceTypeList.length > 0">
          <div class="menu_title">服务分类</div>
          <el-menu
            :default-active="serviceTypeList[0].id + ''"
            active-text-color="#C8A260"
            @select="menuSelect"
          >
            <template v-for="(item, index) in serviceTypeList" :key="index">
              <el-menu-item :index="item.id + ''">
                <template #title>{{ item.name }}</template>
              </el-menu-item>
            </template>
          </el-menu>
        </div>
        <div class="prod_right">
          <div class="noData" v-show="serviceProdList.length <= 0">
            暂无更多产品
          </div>
          <ul class="prod_list">
            <li
              v-for="(item, index) in serviceProdList"
              :key="index"
              @click="consultFn(item.id)"
            >
              <div class="head">
                <img :src="item.itemUrl" alt="" />
              </div>
              <div class="list_bottom">
                <h3>{{ item.typeName }}</h3>
                <span>{{ item.title }}</span>
                <div class="price_con">
                  <div class="left">{{ item.money }}</div>
                  <div class="answerBtn">立即咨询</div>
                </div>
              </div>
            </li>
          </ul>
          <div class="pagination" v-if="serviceTotal > 12">
            <pagination
              :total="serviceTotal"
              v-model:currentPage="serPage.currentPageNum"
              v-model:pageSize="serPage.recordsPerPage"
              @search="searchFn"
            />
          </div>
        </div>
      </div>
    </div>
    <waist />
    <bottomBanner />
  </div>
</template>

<script lang="ts">
import Pagination from '@/common/pagination.vue'
import { serviceTypes, serviceFeatures } from '@/api/entService'
import { PageData } from '@/types/index'
import bottomBanner from '@/common/bottomBanner.vue'
import router from '@/router'
import waist from '../components/waist.vue'
import { defineComponent, ref } from 'vue'
export default defineComponent({
  name: 'freeTrial',
  components: {
    Pagination,
    waist,
    bottomBanner
  },
  setup() {
    // 变量
    const serviceTypeList = ref([])
    const serviceProdList = ref([])
    const serPage = ref<PageData>({ currentPageNum: 1, recordsPerPage: 12 })
    const serviceTotal = ref(0)
    const typeId = ref('')
    // 方法
    // 请求列表
    const searchFn = () => {
      const params = Object.assign(
        { parentTid: typeId.value + '' },
        { paginate: serPage.value }
      )
      serviceFeatures(params).then((res: any) => {
        serviceTotal.value = res.data.total
        res.data.data.forEach((item: any) => {
          if (item.money !== '面议') {
            item.money = '￥' + item.money + '起'
          }
        })
        serviceProdList.value = res.data.data
      })
    }
    // 菜单点击选择
    const menuSelect = (key: any) => {
      typeId.value = key
      serPage.value.currentPageNum = 1
      searchFn()
    }
    // 点击立即咨询
    const consultFn = (id: any) => {
      router.push({
        path: '/entService/serviceConsult',
        query: { id }
      })
    }
    // 获取服务分类
    const getServiceType = () => {
      serviceTypes().then((res: any) => {
        res.data[0].id = ''
        serviceTypeList.value = res.data
        menuSelect(res.data[0].id + '')
      })
    }
    getServiceType()
    return {
      serviceTypeList,
      serviceProdList,
      serPage,
      serviceTotal,
      menuSelect,
      searchFn,
      consultFn
    }
  }
})
</script>

<style lang="scss" scoped>
.freeTrial {
  .banner {
    height: 517px;
    background: url(~assets/images/entService/freeBanner.png) no-repeat 50% 50%;
    background-size: cover;
    color: #fff;
    overflow: hidden;
    p {
      font-size: 28px;
      margin-top: 203px;
    }
    h2 {
      font-size: 56px;
      font-weight: bold;
      margin-top: 30px;
    }
  }
  .prod_container {
    overflow: hidden;
    .title {
      font-size: 32px;
      text-align: center;
      margin: 65px 0 58px 0;
    }
    .prod_content {
      display: flex;
      padding-bottom: 70px;
      .menu {
        width: 256px;
        min-height: 520px;
        background: #ffffff;
        box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.04);
        .menu_title {
          width: 100%;
          height: 59px;
          background: linear-gradient(96deg, #f8fafc 0%, #ffffff 100%);
          font-size: 16px;
          color: #818395;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .prod_right {
        flex: 1;
        .noData {
          height: 100%;
          display: flex;
          justify-content: center;
          margin-top: 200px;
        }
        .prod_list {
          display: flex;
          flex-wrap: wrap;
          li {
            width: 211px;
            position: relative;
            box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.04);
            margin-bottom: 23px;
            margin-left: 25px;
            cursor: pointer;
            .head {
              position: relative;
              width: 100%;
              height: 118px;
              img {
                width: 100%;
                height: 100%;
              }
              p {
                width: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                text-align: center;
                transform: translate(-50%, -50%);
                font-size: 24px;
              }
            }
            .list_bottom {
              height: 150px;
              padding: 15px;
              h3 {
                font-size: 18px;
                color: #444444;
                font-weight: 500;
                margin-bottom: 10px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              span {
                font-size: 14px;
                color: #999999;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-box-orient: vertical;
              }
              .price_con {
                position: absolute;
                width: 85%;
                bottom: 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 15px;
                .left {
                  font-size: 12px;
                  color: #ff504d;
                }
                .answerBtn {
                  width: 73px;
                  height: 27px;
                  background: #ecf3ff;
                  border-radius: 100px;
                  font-size: 12px;
                  color: #3c89ff;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }
            }
          }
        }
        .pagination {
          text-align: center;
          margin-top: 25px;
        }
      }
    }
  }
}
:deep(.el-menu) {
  border: 0;
}
</style>
