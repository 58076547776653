<template>
  <section>
    <div class="waist">
      <h3>企业慧点专属管家 企业服务一站搞定</h3>
      <div class="btn" @click="subInfo">立即咨询</div>
    </div>
  </section>
</template>

<script lang="ts">
import { useStore } from 'vuex'
import {
  defineComponent,
  computed
} from 'vue'
export default defineComponent({
  setup() {
    const stores = useStore()
    const isShow = computed({
      get() {
        return stores.state.sideBarOnlineStatus
      },
      set(v) {
        stores.commit('changeSideBarOnlineStatus', v)
      }
    })
    const subInfo = () => {
      isShow.value = true
    }
    return {
      subInfo
    }
  }
})
</script>

<style lang="scss" scoped>
.waist {
  width: 100%;
  height: 327px;
  background: url(~assets/images/entService/waistBg.png) no-repeat;
  overflow: hidden;
  text-align: center;
  h3 {
    font-size: 32px;
    color: #ffffff;
    margin-top: 87px;
  }
  .btn {
    width: 323px;
    height: 72px;
    background: #0067ed;
    font-size: 22px;
    color: #ffffff;
    margin: 40px auto;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
